import React from 'react';
// import myImage from './Components/Images/webpage.webp';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './Pages/Home/Home'; 
// import Services from './Pages/Services/Services';
// import FAQs from './Pages/FAQs/FAQs';
// import Contact from './Pages/Contact/Contact';
// import Events from './Pages/Events/Events';
// import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
// import Products from './Pages/Products/Products';

// import DisableDevTools from './Components/DisableDevTool/DisableDevTool';

function App() {
  return (
    <div className="centered-image">
    <img 
    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeWU8BBmsKoi41k3JwXfVP8QrbJ7v0YTjKtQ&s"
    alt="Centered" width="500" height="500" />
  </div>

    // <Router>
    // <ScrollToTop/>
    //    <Routes>
    //      <Route path="/" element={<Home />} />
    //      <Route path="/home" element={<Home />} />
    //      <Route path="/services" element={<Services />} />
    //      <Route path="/products" element={<Products />} />
    //      <Route path="/contact" element={<Contact/>} />
    //      <Route path="/faq" element={<FAQs />} />
    //      <Route path='/events' element={<Events/>} />
    //    {/* <DisableDevTools/>  */}
    //    </Routes>
    // </Router>
  );
}

export default App;
